import React, { Component } from "react";
import { userActions } from "../../_actions";
import { connect } from "react-redux";
import "jspdf-autotable";
import LoadingOverlay from "react-loading-overlay";
import "./styles.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Papa from "papaparse";
import moment from "moment";

class FindPassPhrase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      listSuccess: [],
      errorsUser: {},
      fieldsUser: {},
      input1: "",
      input2: "",
      currentPage: 1,
      pageSize: 10,
    };
  }

  componentDidMount() {
    // this.props.dispatch(userActions.clearDataEVM());
    // this.props.dispatch(userActions.clearData());
    const resData2 = {
      keyWord: "",
      pageNo: this.state.currentPage,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: this.state.pageSize,
    };
    this.props.dispatch(
      userActions.getValidPassPhrasesWithAddressList(resData2)
    );
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.savePhraseSuccess) {
      return {
        ...nextProps,

      };
    } else {
      return {
        ...nextProps,
        errorsUser: {}, fieldsUser: {}
      };
    }
  }

  handleCloseModal = () => {
    this.props.dispatch(userActions.clearData());
    this.setState({ errorsUser: {}, fieldsUser: {} });
  };

  // handleInputChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // }
  handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    // console.log(name, value);
    this.setState({ fieldsUser, errorsUser });
  };
  submitHandle = () => {
    // console.log('');
    if (this.validationCheck()) {
      const { passPhrase, index } = this.state.fieldsUser;
      const resData = {
        incompleteMnemonic: passPhrase,
        // replaceIndex: index
      };
      const pageNation = {
        keyWord: "",
        pageNo: 1,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: 20,
      };

      this.props.dispatch(userActions.getValidPassPhrasesWithAddress(resData, pageNation));
    }
  };
  validationCheck = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = {};
    let valid = true;

    if (!fieldsUser["passPhrase"] || fieldsUser["passPhrase"] == "") {
      errorsUser["passPhrase"] = `PassPhrase can't be empty.`;
      valid = false;
    }
    this.setState({ errorsUser: errorsUser });
    return valid;
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      const resData2 = {
        keyWord: "",
        pageNo: this.state.currentPage,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: this.state.pageSize,
      };
      this.props.dispatch(
        userActions.getValidPassPhrasesWithAddressList(resData2)
      );
    });
  };

  renderPagination = () => {
    const { ValidPassPhrasesWithAddressList } = this.props.users;
    const { total } = ValidPassPhrasesWithAddressList
      ? ValidPassPhrasesWithAddressList
      : {};
    const { currentPage, pageSize } = this.state;
    const totalPages = total ? Math.ceil(total / pageSize) : 0;

    if (totalPages <= 1) return null;

    let pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    // Previous Button
    if (currentPage > 1) {
      pages.push(
        <button
          key="prev"
          onClick={() => this.handlePageChange(currentPage - 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Previous
        </button>
      );
    }

    // Page Numbers
    if (startPage > 1) {
      pages.push(
        <span key="first" className="px-4 py-2 mx-1 rounded">
          1
        </span>
      );
      if (startPage > 2) {
        pages.push(
          <span key="dots-prev" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageChange(i)}
          className={`px-4 py-2 mx-1 rounded ${currentPage === i ? "bg-[#CB9731]" : "bg-[#2E2E4E]"
            }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(
          <span key="dots-next" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
      pages.push(
        <button
          key={totalPages}
          onClick={() => this.handlePageChange(totalPages)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          {totalPages}
        </button>
      );
    }

    // Next Button
    if (currentPage < totalPages) {
      pages.push(
        <button
          key="next"
          onClick={() => this.handlePageChange(currentPage + 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Next
        </button>
      );
    }

    return <div className="flex justify-center mt-4">{pages}</div>;
  };
  navigatePhraseDetails = (data) => {
    this.props.history.push(`/app/twomissingpassDetails/${data.id}`);
  };

  render() {
    let { users } = this.props;
    const { passPhrase, index } = this.state.fieldsUser;
    const {
      loading,
      validBtcPassPhrases,
      validBNBPassPhrases,
      validETHPassPhrases,
      validSegWitPassPhrases,
      validLegacyPassPhrases,
      ValidPassPhrasesWithAddressList,
    } = users;

    console.log("validBNBPassPhrases", validBNBPassPhrases);
    console.log("validETHPassPhrases", validETHPassPhrases);
    console.log("validSegWitPassPhrases", validSegWitPassPhrases);
    console.log("validLegacyPassPhrases", validLegacyPassPhrases);
    console.log(
      "ValidPassPhrasesWithAddressList",
      ValidPassPhrasesWithAddressList
    );

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text="Please wait..."
          />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <section>
            <div className="overflow-hidden">
              <div className="overflow-y-auto">
                <div className="p-8 space-y-3 ">
                  <div className="flex flex-col justify-center place-content-center">
                    <div className=" w-[35rem] mx-auto ">
                      <label className="text-[#CB9731]">PassPhrase</label>
                      <div className="my-2">
                        <input
                          type="text"
                          id="passPhrase"
                          name="passPhrase"
                          value={passPhrase ?? ""}
                          onChange={this.handleInputChange}
                          className="w-full p-2 border rounded"
                          placeholder="PassPhrase"
                        />
                        {this.state.errorsUser["passPhrase"] ? (
                          <div className="text-xs italic text-red-500 invalid-feedback">
                            {this.state.errorsUser["passPhrase"]}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <button
                      className="px-5 py-1 bg-[#CB9731] rounded-md my-2 mx-auto"
                      onClick={() => this.submitHandle()}
                    >
                      Submit
                    </button>
                  </div>

                  <section className="rounded-lg">
                    <div className="max-w-full overflow-auto">
                      {/* <div className=' ' > */}
                      <button
                        className="bg-[#CB9731] rounded-md text-white px-6 py-2"
                        onClick={() => this.handleCloseModal()}
                      >
                        clear
                      </button>
                      {/* </div> */}
                      <div className="text-white font-bold text-center text-xl">
                        {validBtcPassPhrases?.validCount ?? null}
                      </div>
                      <div className="inline-block min-w-full">
                        <section className="rounded-lg">
                          <div className="max-w-full overflow-auto">
                            <div className="inline-block min-w-full">
                              <table className="min-w-full bg-[#24243C] rounded-lg">
                                <thead className="text-white  text-center uppercase ">
                                  PassPhrases
                                  <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                    <th className="px-3 py-3 whitespace-nowrap">
                                      S/n
                                    </th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">
                                      isCronTab
                                    </th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">
                                      createdAt
                                    </th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">
                                      passphrases
                                    </th>
                                    <th className="px-3 py-3 whitespace-nowrap capitalize">
                                      action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ValidPassPhrasesWithAddressList &&
                                    ValidPassPhrasesWithAddressList.list &&
                                    ValidPassPhrasesWithAddressList.list.length >
                                    0
                                    ? ValidPassPhrasesWithAddressList.list.map(
                                      (element, index) => (
                                        <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {this.state.offset + index + 1}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {element && element.isCronTab
                                              ? element.isCronTab
                                              : 0}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {/* {element && element.createdAt
                                              ? element.createdAt
                                              : "-"} */}
                                            {element && element.createdAt ? moment(element.createdAt).format('DD-MM-YYYY HH:mm') : null}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {element && element.passPhrase
                                              ? element.passPhrase
                                              : "-"}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            <span
                                              onClick={() =>
                                                this.navigatePhraseDetails(
                                                  element
                                                )
                                              }
                                              className="bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer"
                                            >
                                              More Details
                                            </span>
                                          </td>
                                        </tr>
                                      )
                                    )
                                    : null}
                                </tbody>
                              </table>
                              {this.renderPagination()}
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  console.log("usersusersusersusersusersusers", users);
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,
  };
}
export default connect(mapStateToProps)(FindPassPhrase);
