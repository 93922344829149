import { authHeader } from '../_helpers';
import { CONST } from '../_config';
// import moment from 'moment';


export const dashboardService = {
    getAllEmoji,
    adddashboard,
    deletedashboardService,
    getAllResourceList,
    saveAssignResourcedashboard,
    getAssignedResourceList,
    udpateStatus,
    getCoinList,
    getLoginhistory,
    getAllCoinCard,
    getMiningPftShareList,
    getWelcomeBonusList,
    getLTArchiveRewardList,
    getFastrackBonusList,
    buyPackage,
    getUserPackagesTeam,
    getUserPackagesTeamTotalCount,
    getUserPackages,
    getCmcHistoryDftcList,
    getTxAllMultiTeam,
    getTxAllMulti,
    getAllLevel,
    convertToMainBalance,
    getAppSetting,
    getTeamUserPackageLastLevelById,
};
function logout() {
    // remove user from local storage to log user out
    window.sessionStorage.removeItem('user');
    window.location.href = "#/home"
}

function getAppSetting() {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify()
    }
    return fetch(CONST.BACKEND_URL + `/getAppSetting`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getAppSetting: res.data
            }
            // console.log("in service getAppSettinggetAppSettinggetAppSetting ", res.data);
            return userObj;
        });
}
function getTeamUserPackageLastLevelById() {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify()
    }
    return fetch(CONST.BACKEND_URL + `/getTeamUserPackageLastLevelById`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getTeamUserPackageLastLevelById: res.data
            }
            // console.log("in service getTeamUserPackageLastLevelByIdgetTeamUserPackageLastLevelByIdgetTeamUserPackageLastLevelById ", res.data);
            return userObj;
        });
}

function convertToMainBalance() {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify()
    }
    return fetch(CONST.BACKEND_URL + `/convertToMainBalance`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                convertToMainBalance: res.data
            }
            // console.log("in service convertToMainBalanceconvertToMainBalanceconvertToMainBalance ", res.data);
            return userObj;
        });
}

function getAllLevel(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllLevel`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getAllLevel: res.data
            }
            // console.log("in service getAllLevelgetAllLevelgetAllLevel ", res.data);
            return userObj;
        });
}
function getTxAllMulti(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTxAllMulti`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getTxAllMulti: res.data
            }
            // console.log("in service getTxAllMultigetTxAllMultigetTxAllMulti ", res.data);
            return userObj;
        });
}

function getTxAllMultiTeam(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTxAllMultiTeam`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getTxAllMultiTeam: res.data
            }
            // console.log("in service getTxAllMultiTeamgetTxAllMultiTeamgetTxAllMultiTeam ", res.data);
            return userObj;
        });
}

function getLoginhistory(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getLoginhistory`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getLoginhistory: res.data
            }
            // console.log("in service getLoginhistory ", userObj);
            return userObj;
        });
}

function getAllCoinCard(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllCoinCard`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getAllCoinCard: res.data
            }
            // console.log("in service getAllCoinCard ", userObj);
            return userObj;
        });
}

function getMiningPftShareList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getMiningPftShareList`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getMiningPftShareList: res.data
            }
            // console.log("in service getMiningPftShareList ", userObj);
            return userObj;
        });
}

function getWelcomeBonusList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        // body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getWelcomeBonusList`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getWelcomeBonusList: res.data
            }
            // console.log("in service getWelcomeBonusList ", userObj);
            return userObj;
        });
}

function getLTArchiveRewardList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getLTArchiveRewardList`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getLTArchiveRewardList: res.data
            }
            // console.log("in service getLTArchiveRewardList ", userObj);
            return userObj;
        });
}

function getUserPackages() {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
    }
    return fetch(CONST.BACKEND_URL + `/getUserPackages`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserPackages: data.data
            }
            // console.log("I am in service getUserPackages::", userObj);
            return userObj;
        });
}

function getUserPackagesTeam() {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getUserPackagesTeam`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getUserPackagesTeam: res.data
            }
            // console.log("in service getUserPackagesTeam ", userObj);
            return userObj;
        });
}
function getUserPackagesTeamTotalCount() {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getUserPackagesTeamVolume`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getUserPackagesTeamTotalCount: res.data
            }
            // console.log("in service getUserPackagesTeamTotalCount ", userObj);
            return userObj;
        });
}



function getFastrackBonusList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getFastrackBonusList`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                getFastrackBonusList: res.data
            }
            // console.log("in service getFastrackBonusList ", userObj);
            return userObj;
        });
}

function getCoinList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCoinList`, requestOptions)
        .then(handleResponse)
        .then(res => {
            console.log(res, 'res741741741741741741741741741');
            let userObj = {
                getCoinList: res.data
            }
            // console.log("in service getCoinList ", userObj);
            return userObj;
        });
}

function getCmcHistoryDftcList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCmcHistoryList`, requestOptions)
        .then(handleResponse)
        .then(res => {

            // console.log("res_________res:::=======>getCmcHistoryDftcList?", res.data);
            // let tempdata = {
            //     tempData1: {},
            //     tempData2: {}
            // }
            // let tempData1 = {
            //     grid: { top: 8, right: 8, bottom: 24, left: 36 },
            //     // xAxis: {
            //     //     type: 'category',
            //     //     data: res.data.map((element) => (element.createdAt2)),
            //     // },

            //     // yAxis: {
            //     //     type: 'value',
            //     // },
            //     series: [
            //         {
            //             name: res.data.map((element) => (element.createdAt)),
            //             radius: '55%',
            //             // center: ['50%', '50%'],
            //             data: res.data.map((element) => (element.dollarPrice)),
            //             type: 'pie',
            //             smooth: 0.2,
            //             // length: 10,
            //             // length2: 20
            //         },
            //     ],
            //     tooltip: {
            //         trigger: 'item',
            //     },
            // };
            let tempData = {
                grid: { top: 8, right: 8, bottom: 24, left: 36 },
                xAxis: {
                    type: 'category',
                    data: res.data.map((element) => (element.createdAt)),
                },

                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        data: res.data.map((element) => (element.dollarPrice)),
                        type: 'line',
                        smooth: true,
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                },
            };

            // let tempData = {
            //     optionsMixedChart: {
            //         chart: {
            //             id: "basic-bar",
            //             toolbar: {
            //                 show: false
            //             }
            //         },
            //         plotOptions: {
            //             bar: {
            //                 columnWidth: "50%"
            //             }
            //         },
            //         stroke: {
            //             width: [4, 0, 0]
            //         },
            //         xaxis: {
            //             // categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
            //             categories: res.data.map((element) => (moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")))
            //         },
            //         markers: {
            //             size: 6,
            //             strokeWidth: 3,
            //             fillOpacity: 0,
            //             strokeOpacity: 0,
            //             hover: {
            //                 size: 8
            //             }
            //         },
            //         yaxis: {
            //             tickAmount: 5,
            //             min: 0,
            //             max: 1000
            //         }
            //     },



            //     seriesMixedChart: [
            //         {
            //             name: "DFTC Price",
            //             type: "column",
            //             data: res.data.map((element) => (element.dollarPrice.toFixed(2))),
            //         },
            //         // {
            //         //     name: "DFTC Price",
            //         //     type: "line",
            //         //     data: res.data.map((element) => (element.dollarPrice.toFixed(2))),
            //         // }
            //     ],

            // };

            let userObj = {
                getCmcHistoryDftcListGraph: tempData,
                // getCmcHistoryDftcListPie: tempData1,
            }
            // console.log("In_Service_getCmcHistoryDftcList!!!!!!!!!!!!!!!!!!!!!!!! ", userObj);
            return userObj;
        });
}

function getAllEmoji(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "GET",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getAllEmoji`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                dashboarddata: res.data
            }
            return userObj;
        });
}

function buyPackage(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/buyPackageTest`, requestOptions)
        .then(handleResponse)
        .then(res => {
            // console.log("IN___Service____buyPackage_____resresresresresresresres::::", res);

            let userObj = {
                buyPackage: res
            }
            // console.log("IN___Service____buyPackage_____userObj::::", userObj);
            return userObj;
        });
}
function adddashboard(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/api/adddashboard`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                adddashboardres: res.data
            }
            return userObj;
        });
}
function deletedashboardService(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/api/deletedashboard`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                adddashboardres: res.data
            }
            return userObj;
        });
}
function getAllResourceList() {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/api/listresoures`, requestOptions)
        .then(handleResponse)
        .then(res => {

            let userObj = {
                listOfResource: res.data
            }
            return userObj;
        });
}
function getAssignedResourceList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/api/getassignrole`, requestOptions)
        .then(handleResponse)
        .then(res => {

            let userObj = {
                listOfAssignedResource: res.data
            }
            return userObj;
        });
}
function saveAssignResourcedashboard(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });

    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/api/assignrole`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                updateResource: res
            }
            return userObj;
        });
}
function udpateStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/api/updatedashboardstatus`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                updatestatus: res.data
            }
            return userObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}